<script lang="ts" setup>
import { computed, onMounted, ref, watch } from 'vue';
import * as yup from 'yup';
import * as client from '@gabrielcam/api-client';
import { useRoute } from 'vue-router';
import { ErrorMessage, useField, useForm } from 'vee-validate';
import { useApplicationStore } from '@stores/application';
import { useViewStore, View } from '@stores/view';
import {
  AlertVariant,
  ButtonType,
  ButtonVariant,
  CardVariant,
  HeadingVariant,
  PageNames,
  ToggleVariant,
} from '@viewModels/enums';
import { IconName, IconStyle } from '@viewModels/heroIcons';
import AlertBanner from '@components/AlertBanner.vue';
import ButtonComponent from '@components/ButtonComponent.vue';
import ContainerCard from '@components/cards/ContainerCard.vue';
import Loading from '@components/Loading.vue';
import ButtonContainer from '@layouts/ButtonContainer.vue';
import Heading from '@components/Heading.vue';
import { extractErrorFields, extractErrorMessage } from '@utils/errorUtils';
import HorizontalRule from '@components/HorizontalRule.vue';
import ToggleComponent from '@components/ToggleComponent.vue';

// Form Interface
interface ViewUpdateForm {
  name: string;
  camera?: string;
  cameraOffset: number;
  client: string;
  project: string;
  longitude: number;
  latitude: number;
  isPublic: boolean;
  publicImageOffsetHours: number;
  bucket?: string;
  prefix?: string;
}

// Form Validation
const { handleSubmit, setErrors } = useForm<ViewUpdateForm>({
  validationSchema: yup.object({
    name: yup.string().required(),
    camera: yup.string().nullable(),
    client: yup.string().nullable(),
    project: yup.string().nullable(),
    longitude: yup.number().required(),
    latitude: yup.number().required(),
    isPublic: yup.boolean().required(),
  }),
});

// Form Fields
const { value: nameValue } = useField<string>('name', 'name');
const { value: cameraValue } = useField<string | undefined>('camera', 'camera');
const { value: cameraOffsetValue } = useField<number | undefined>('cameraOffset', 'cameraOffset');
const { value: clientValue } = useField<string>('client', 'client');
const { value: projectValue, resetField: resetProjectfield } = useField<string>('project', 'project');
const { value: longitudeValue } = useField<number>('longitude', 'longitude');
const { value: latitudeValue } = useField<number>('latitude', 'latitude');
const { value: isPublicValue } = useField<boolean>('isPublic', 'isPublic');
const { value: publicImageOffsetHoursValue } = useField<number>('publicImageOffsetHours', 'publicImageOffsetHours');

const route = useRoute();
const viewStore = useViewStore();
const applicationStore = useApplicationStore();
const viewId = route.params['id'] as string;
const isLoading = ref<boolean>(true);
const isSubmitting = ref<boolean>(false);
const currentView = ref<View>();
const cameras = ref<client.Camera[]>([]);
const filteredCameras = ref<client.Camera[]>([]);
const clients = ref<client.Client[]>([]);
const filteredClients = ref<client.Client[]>([]);
const locationAlertMessage = ref<string>('');
const alertVariant = ref<AlertVariant>(AlertVariant.Light);
const projects = ref<client.Project[]>([]);
const filteredProjects = ref<client.Project[]>([]);
const publicUrl = ref<string | null>(null);
const basePublicUrl = `${window.location.origin}/views/public/`;

// Handle form submission
const onSubmit = handleSubmit(async (values) => {
  isSubmitting.value = true;

  try {
    await client.updateViewById({ viewId,
      requestBody: {
        name: values.name,
        camera: values.camera || undefined,
        cameraOffsetHours: values.camera ? values.cameraOffset : undefined,
        client: values.client || undefined,
        project: values.project || undefined,
        longitude: values.longitude,
        latitude: values.latitude,
        isPublic: values.isPublic,
        publicImageOffsetHours: values.publicImageOffsetHours,
    }})

    // Update the public URL if the view is public
    if (values.isPublic) {
      publicUrl.value = `${basePublicUrl}${viewId}`;
    } else {
      publicUrl.value = null;
    }

    applicationStore.publishSuccessNotification({
      text: 'Successfully updated view.',
      autoCloseMs: 3000,
    });

  } catch (error) {
    // Extract the error message from the error object
    const errorMessage = extractErrorMessage(error);
    const errorFields = extractErrorFields(error)

    if (errorFields) {
      setErrors(errorFields);
    }

    applicationStore.publishErrorNotification({
      text: `Error updating view: ${errorMessage}`,
    });
  } finally {
    isSubmitting.value = false;
  }
});

const fetchViewData = async (): Promise<void> => {
  currentView.value = await viewStore.getViewById(viewId);
  nameValue.value = currentView.value.name;
  cameraValue.value = currentView.value.camera;
  cameraOffsetValue.value = currentView.value.cameraOffsetHours ?? 0;
  clientValue.value = currentView.value.client!;
  projectValue.value = currentView.value.project!;
  longitudeValue.value = currentView.value.longitude ?? 0;
  latitudeValue.value = currentView.value.latitude ?? 0;
  isPublicValue.value = currentView.value.isPublic ?? false;
  cameraValue.value = currentView.value.camera; // Save camera ID for fetching live stats
  publicImageOffsetHoursValue.value = currentView.value.publicImageOffsetHours ?? 0;

  // Set the publicUrl after fetching data
  if (isPublicValue.value) {
    publicUrl.value = `${basePublicUrl}${viewId}`;
  } else {
    publicUrl.value = null;
  }

  try {
    // Fetch live camera stats using the cameraId
    const cameraStats = await client.getCameraByIdStatLatest({
      cameraId: cameraValue.value,
    } as client.GetCameraByIdStatLatestData);

    // Check if cameraStats contains valid live latitude and longitude
    if (cameraStats && cameraStats.gnssLatitude != null && cameraStats.gnssLongitude != null) {
      const liveLatitude = cameraStats.gnssLatitude / 1000000;
      const liveLongitude = cameraStats.gnssLongitude / 1000000;

      // Set dynamic alert to inform the user that manual input will override the live data
      alertVariant.value = AlertVariant.Warning;
      locationAlertMessage.value = `We have live data for the latitude (${liveLatitude}) and longitude (${liveLongitude}) for this Camera.
      By using the manual latitude & longitude above, you will overrule the reported live location on the Map.`;
    } else {
      // No valid live data or error occurred
      alertVariant.value = AlertVariant.Warning;
      locationAlertMessage.value = `There is no live latitude or longitude coordinates available right now. The manual data above will be used instead.`;
    }
  } catch (error) {
    console.error(`Failed to fetch live stats for camera ${cameraValue.value}`, error);
    // Fallback error message is the same as "no valid live data"
    alertVariant.value = AlertVariant.Warning;
    locationAlertMessage.value = `There is no live latitude or longitude coordinates available right now. The manual data above will be used instead.`;
  }
};

watch(() => clientValue.value,
  async (value, oldValue) => {
  if (!value || (oldValue && oldValue !== value)) {
    resetProjectfield();
  }

  if (value) {
    projects.value = (await client.listProjects({
      organisation: applicationStore.activeOrganisation!.id,
      client: clientValue.value
    })).data;
    filteredProjects.value = [...projects.value];
  }
})

onMounted(async () => {
  await fetchViewData();

  cameras.value = (await client.listCameras({ organisation: applicationStore.activeOrganisation!.id })).data;
  filteredCameras.value = [...cameras.value];

  clients.value = (await client.listClients({ organisation: applicationStore.activeOrganisation!.id })).data;
  filteredClients.value = [...clients.value];

  isLoading.value = false;
});

const projectPlaceHolder = computed(() => clientValue.value
  ? 'Please select (optional)'
  : 'Please select Client first'
);

const CameraOffsetOptions = Array.from({ length: 47 }, (_, i) => i - 23);
const PublicViewOffsetHoursOptions = Array.from({ length: 49 }, (_, i) => i);
</script>

<template>
  <ContainerCard>
    <Loading v-if="isLoading" />

    <form v-if="!isLoading"
          @submit.prevent="onSubmit">
      <div class="field-group">
        <div class="field-group-info">
          <Heading level="3">
            Edit View
          </Heading>
          <p>Update a view.</p>
        </div>

        <div class="fields">
          <div class="row-half">
            <div class="field">
              <label for="view-name">Name</label>
              <input id="view-name"
                     v-model="nameValue"
                     type="text">
              <ErrorMessage name="name" class="message message-error" as="p" />
            </div>
            <div class="field" />
          </div>

          <div class="field">
            <label for="model">Client</label>
            <v-select v-model="clientValue"
                      :options="filteredClients"
                      :reduce="(client: client.Client) => client.id"
                      label="name"
                      placeholder="Please select (optional)"
                      @search="
                        (search: string) => {
                          filteredProjects = clients.filter((x) => {
                            if (!!search.length) return true;
                            return x.name.toLowerCase().includes(search.toLowerCase());
                          });
                        }
                      " />
            <ErrorMessage name="client" class="message message-error" as="p" />
          </div>

          <div class="field">
            <label for="model">Project</label>
            <v-select v-model="projectValue"
                      :disabled="!clientValue"
                      :options="filteredProjects"
                      :reduce="(project: client.Project) => project.id"
                      label="name"
                      :placeholder="projectPlaceHolder"
                      @search="
                        (search: string) => {
                          filteredProjects = projects.filter((x) => {
                            if (!!search.length) return true;
                            return x.name.toLowerCase().includes(search.toLowerCase());
                          });
                        }
                      " />
            <ErrorMessage name="project" class="message message-error" as="p" />
          </div>

          <div class="field">
            <label for="model">Camera</label>
            <v-select v-model="cameraValue"
                      :options="filteredCameras"
                      :reduce="(camera: client.Camera) => camera.id"
                      label="serialNumber"
                      placeholder="Please select (optional)"
                      @search="
                        (search: string) => {
                          filteredCameras = cameras.filter((x) => {
                            if (!!search.length) return true;
                            return x.serialNumber.toLowerCase().includes(search.toLowerCase());
                          });
                        }
                      " />
            <ErrorMessage name="camera" class="message message-error" as="p" />
          </div>

          <div v-if="cameraValue" class="field">
            <label for="model">Camera capture offset (hours)</label>
            <v-select v-model="cameraOffsetValue"
                      label="serialNumber"
                      :clearable="false"
                      :options="CameraOffsetOptions" />
            <ErrorMessage name="cameraOffset" class="message message-error" as="p" />
          </div>

          <AlertBanner v-if="cameraValue"
                       :variant="AlertVariant.Info"
                       :has-bottom-margin="true"
                       :icon-name="IconName.InformationCircleIcon"
                       :icon-style="IconStyle.Outline">
            <template #mainContent>
              <span class="text--size-xs">
                If your camera is not configured to use the UTC Timezone you may need to define an offset to correct the capture time.
              </span>
            </template>
          </AlertBanner>

          <div class="row-half">
            <div class="field">
              <label for="model">Latitude</label>
              <input id="view-name"
                     v-model="latitudeValue"
                     type="text">
              <ErrorMessage name="latitude" class="message message-error" as="p" />
            </div>
            <div class="field">
              <label for="model">Longitude</label>
              <input id="view-name"
                     v-model="longitudeValue"
                     type="text">
              <ErrorMessage name="longitude" class="message message-error" as="p" />
            </div>
          </div>

          <AlertBanner v-if="cameraValue"
                       :variant="alertVariant as AlertVariant"
                       :has-bottom-margin="true"
                       :icon-name="IconName.InformationCircleIcon"
                       :icon-style="IconStyle.Outline">
            <template #mainContent>
              <span class="text--size-xs">{{ locationAlertMessage }}</span>
            </template>
          </AlertBanner>

          <HorizontalRule />

          <!-- Danger Zone -->
          <Heading level="3" :variant="HeadingVariant.Danger" :has-bottom-margin="true">
            Danger Zone
          </Heading>
          <ContainerCard :variant="CardVariant.TransparentDanger" :has-border="true">
            <Heading level="5" :variant="HeadingVariant.Danger">
              Public URL
            </Heading>
            <p class="text--size-xs text--danger">
              Warning: Activating the Public URL creates a hyperlink that makes this view publicly accessible. Anyone with the link will be able to access it. You can optionally delay displaying images by a number of hours.
            </p>
            <ToggleComponent :model-value="isPublicValue"
                             :variant="ToggleVariant.Danger"
                             :title="isPublicValue ? 'Disable Public URL' : 'Enable Public URL'"
                             @update:model-value="isPublicValue = $event" />
            <div v-show="publicUrl" class="word-wrap">
              <template v-if="publicUrl">
                <p class="text--size-xs">
                  <strong>Public URL: </strong>
                  <a v-if="publicUrl"
                     :href="publicUrl"
                     class="break-word text--lowercase"
                     target="_blank">{{ publicUrl }}</a>
                </p>

                <div class="field">
                  <label>Image delay (hours)</label>
                  <v-select v-model="publicImageOffsetHoursValue"
                            :clearable="false"
                            :options="PublicViewOffsetHoursOptions" />
                  <ErrorMessage name="publicViewOffsetHours" class="message message-error" as="p" />
                </div>
              </template>
            </div>
          </ContainerCard>
        </div>
      </div>

      <ButtonContainer>
        <ButtonComponent :variant="ButtonVariant.Dark"
                         :is-outline-btn="true"
                         :is-block-btn="true"
                         :type="ButtonType.Button"
                         :to="{ name: PageNames.ViewOverview, params: { id: viewId } }">
          Cancel
        </ButtonComponent>
        <ButtonComponent :variant="ButtonVariant.Dark"
                         :loading="isSubmitting"
                         :is-block-btn="true"
                         :type="ButtonType.Submit">
          Save
        </ButtonComponent>
      </ButtonContainer>
    </form>
  </ContainerCard>
</template>

<style lang="scss" scoped>
.checkbox-field {
  & .checkbox-label {
    margin-left: 10px;
    font-weight: normal;
  }

  & input[type='checkbox'] {
    flex-shrink: 0;
  }
}

.word-wrap {
  display: inline-block;
  width: 100%;
}

.break-word {
  word-break: break-all;
  word-wrap: break-word;
  overflow-wrap: break-word;
}
</style>
